<template>
    <b-container fluid id="profile">
        <h4 class="card-title mb-4">{{ $t('Profile.AccountSettings') }}</h4>

        <cg-loader :display="inProgress"></cg-loader>

        <cg-localized-alert :alertVariant="alertVariant" ref="cgLocAlert" :alertMessage="alertMessage"></cg-localized-alert>

        <b-row>
            <b-col sm="12" lg="6">

                <!-- First card, Profile info -->
                <b-card class="light-footer">
                    <b-form-group label-cols-lg="12" :label="$t('Profile.MyAccount')" label-size="lg" label-class="label-profile pt-0">

                        <!-- Displayname -->
                        <b-form-group class="form-align-self-center" label-cols-sm="3" :label="$t('Profile.DisplayName')" label-align-sm="right"
                            label-for="userDisplayName">
                            <b-form-input id="userDisplayName" plaintext v-model="user.displayname"></b-form-input>
                        </b-form-group>

                        <!-- Email -->
                        <b-form-group class="form-align-self-center" label-cols-sm="3" :label="$t('Profile.Email')" label-align-sm="right"
                            label-for="userEmail">
                            <b-form-input id="userEmail" plaintext v-model="user.email"></b-form-input>
                        </b-form-group>
                    </b-form-group>

                    <template v-if="$checkPermission('change-password')" v-slot:footer>
                        <b-row align-h="end">
                            <b-col cols="auto">
                                <b-button type="button" variant="primary" :disabled="ldapEnabled" v-b-modal.change-password-modal>{{ $t('Profile.ChangePassword') }}</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
            </b-col>

            <b-col md="12" lg="6">

                <!-- Second card, Profile settings -->
                <b-card>
                    <b-form-group label-cols-lg="12" :label="$t('Profile.Preferences')" label-size="lg" label-class="label-profile pt-0">
                        
                        <!-- Language -->
                        <b-form-group class="form-align-self-center" label-cols-sm="3" :label="$t('Profile.AvailableLanguages')" label-align-sm="right"
                            label-for="availableLanguages">
                            <b-form-select id="availableLanguages" size="sm" v-model="language" @change="updateLocale" :options="$langOptions">
                            </b-form-select>
                        </b-form-group>

                        <!-- Timezone -->
                        <b-form-group class="form-align-self-center" label-cols-sm="3" :label="$t('Profile.Timezone')"
                            label-align-sm="right" label-for="timezone">
                            <b-form-select id="timezone" size="sm" v-model="timezone" @change="updateLocale" :options="allTimezones"></b-form-select>
                        </b-form-group>
                    </b-form-group>

                    <template v-slot:footer>
                        <b-card-text v-html="supportMessage"></b-card-text>
                    </template>
                </b-card>
            </b-col>
        </b-row>

        <!-- Change password modal -->
        <b-modal id="change-password-modal" :title="$t('Profile.ChangePassword')" @show="cleanForm" hide-footer>
            <cg-localized-alert :alertVariant="alertVariant" ref="cgPwdAlert" :alertMessage="alertMessage" :alertParams="alertParams"></cg-localized-alert>

            <b-form @submit="onSubmit" @reset="onReset">

                <!-- Old password -->
                <b-form-group :state="oldPasswordState" :label="$t('Profile.OldPassword')" label-for="old-password-input">
                    <b-form-input id="old-password-input" v-model="form.oldPassword" :state="oldPasswordState" type="password" required></b-form-input>
                </b-form-group>

                <!-- New password -->
                <b-form-group :state="newPasswordState" :label="$t('Profile.NewPassword')" label-for="new-password-input">
                    <b-form-input id="new-password-input" autocomplete="new-password" v-model="form.newPassword" :state="newPasswordState" type="password" required></b-form-input>
                </b-form-group>

                <!-- Confirm password -->
                <b-form-group class="cg-form-group-description-space" :state="confirmPasswordState" :label="$t('Profile.ConfirmPassword')" 
                    label-for="confirm-password-input" :invalid-feedback="$t('Profile.PasswordsMismatch')">
                    <b-form-input id="confirm-password-input" autocomplete="new-password" v-model="form.confirmPassword" :state="confirmPasswordState" 
                        type="password" required></b-form-input>
                </b-form-group>

                <b-row align-h="end" class="cg-modal-footer pt-3 pb-1">
                    <b-col cols="auto">
                        <b-button type="reset">{{ $t('General.Cancel') }}</b-button>
                        <b-button type="submit" variant="primary" :disabled="!oldPasswordState || !newPasswordState || !confirmPasswordState">{{ $t('Profile.ChangePassword') }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>

    </b-container>
</template>

<script>
import accountService from "@/services/account.service.js";
import syspropService from "@/services/sysprop.service.js"

export default {
    name: "cg-profile",
    data: function() {
        return {
            user: {
                displayname: "",
                email: "",
                account_id: "",
                language: ""
            },
            form: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            },
            inProgress: false,
            alertVariant: "danger",
            alertMessage: "",
            alertParams: {},
            supportMessage: null,
            supportEmail: null,
            language: "",
            languageLabel: "",
            avaibleLanguages: this.$langOptions,
            ldapEnabled: null,
            passwordSettings: null
        };
    },
    computed: {
        oldPasswordState() {
            return this.form.oldPassword.length == 0 ? null : true;
        },
        newPasswordState() {
            return this.form.newPassword.length == 0 ? null : this.form.newPassword.length > 1 ? true : false;
        },
        confirmPasswordState() {
            return this.form.confirmPassword.length == 0 ? 
                null : this.form.confirmPassword.length > 1 && this.form.confirmPassword == this.form.newPassword ? true : false;
        }
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            this.inProgress = true;

            let checkPassword = this.checkPasswordStrength(this.form.newPassword);
            if(checkPassword && checkPassword.length > 0) {
                this.alertMessage = 'Account.AccountPasswordError';
                this.alertParams = { param: checkPassword };
                this.alertVariant = 'danger';
                this.inProgress = false;
                this.$refs.cgPwdAlert.showAlert();
                return;
            }

            let body = {
                email: this.user.email,
                oldpassword: this.form.oldPassword,
                newpassword: this.form.newPassword
            };

            accountService.changePassword(this.$account.account_id,body).then((res) => {
                console.debug("Profile - changePassword success", res);
                this.alertVariant = "success";
                this.alertMessage = "Profile.PasswordChanged";
            }).catch((err) => {
                console.error("Profile - changePassword error", err);
                this.alertVariant = "danger";
                this.alertMessage = "Profile.PasswordChangeError";
            }).finally(() => {
                this.inProgress = false;
                this.cleanForm();
                this.$refs.cgLocAlert.showAlert();
            });
        },
        onReset(evt) {
            evt.preventDefault();
            this.cleanForm();
        },
        cleanForm() {
            this.form.oldPassword = "";
            this.form.newPassword = "";
            this.form.confirmPassword = "";
            this.$nextTick(() => {
                this.$bvModal.hide("change-password-modal");
            });
        },
		checkPasswordStrength(password) {
			const countUppercaseChars = (password.match(/[A-Z]/g) || []).length;
			const countLowercaseChars = (password.match(/[a-z]/g) || []).length;
			const countSpecialChars = (password.match(/[-!$%^&*()_+|~=`{};<>?,.@#]/g) || []).length;
			const countNumbers = (password.match(/[0-9]/g) || []).length;
            
			let errors = [];
			if(countUppercaseChars < this.passwordSettings.min_uppercase) {
				errors.push(this.$tc('Account.PasswordUppercaseError', (this.passwordSettings.min_uppercase)));
			}
			if (countSpecialChars < this.passwordSettings.min_special_chars) {
				errors.push(this.$tc('Account.PasswordSpecialCharsError', (this.passwordSettings.min_special_chars)));
			} 
			if (countLowercaseChars < this.passwordSettings.min_lowercase) {
				errors.push(this.$tc('Account.PasswordLowercaseError', (this.passwordSettings.min_lowercase)));
			}
			if(countNumbers < this.passwordSettings.min_numbers) {
				errors.push(this.$tc('Account.PasswordNumbersError', (this.passwordSettings.min_numbers)));
			}
			if (password.length < this.passwordSettings.min_length) {
				errors.push(this.$tc('Account.PasswordMinLengthError', (this.passwordSettings.min_length)));
			}

			return errors.join(', ');
		},
        getUser() {
            this.user.displayname = this.$account.displayname;
            this.user.id = this.$account.account_id;
            this.user.email = this.$account.email;
            this.language = this.$account.language;
            this.languageLabel = this.$i18n.t("Languages." + this.$account.language);
			this.timezone = this.$account.timezone;
        },
        updateLocale() {
            this.inProgress = true;

            accountService.updateAccountById(this.user.id, { language: this.language, timezone: this.timezone }).then((res) => {
                console.debug("Profile - updateAccount success", res);
                this.$i18n.locale = this.language;
                this.$langOptions.forEach(opt => {
                    opt.text = this.$t(`Languages.${opt.value}`);
                });
                this.$account.language = this.language;
                this.$account.timezone = this.timezone;
                this.languageLabel = this.$i18n.t("Languages." + this.language);
                this.$emit('languageChanged', this.language);
                this.alertVariant = "success";
                this.alertMessage = "Profile.AccountModified";
                this.updateSupportMessage();
            }).catch((err) => {
                console.error("Profile - updateAccount error", err);
                this.alertVariant = "danger";
                this.alertMessage = "Profile.AccountModifyError";
            }).finally(() => {
                this.inProgress = false;
                this.$refs.cgLocAlert.showAlert();
            });
        },
        updateSupportMessage() {
            this.supportMessage = this.supportEmail? this.$t('Profile.ContactUs', { param: this.supportEmail }) : this.$t('Profile.ContactSupport');
        }
    },
    async created() {
        this.getUser();
        this.allTimezones = this.$moment.tz.names();

        syspropService.getConfig().then((res) => {
            this.passwordSettings = res.data && res.data.password_settings;
            this.supportEmail = res.data && res.data.contact;
            this.updateSupportMessage();
            let ldap = res.data.ldap.enabled || null; 
            this.ldapEnabled = ldap == 'forced' || (ldap == 'true' && this.$account.ldap_authentication == 1);
		});
    }
};
</script>

<style lang="less">
#profile {
    .card {
        min-height: 320px;
        margin-bottom: 16px;
        .card-body {
            display: flex;
            flex-direction: column;
        }
        &.light-footer .card-footer {
            background-color: transparent;
            border: none;
            padding-bottom: 20px;
            padding-top: 0px;
        }
    }
    .form-align-self-center {
        div {
            align-self: center !important;
        }
        color: @cg-dark-gray-text-color;
    }
    .form-group {
        border-bottom: @cg-border-light;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }
    .form-group:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .label-profile {
        font-weight: 500;
        line-height: 1.2;
    }
    .modal-dialog .white-overlay .alert-wrapper {
        width: 480px !important;
    }

    @media only screen and (max-width: 576px) { 
        .modal-dialog .white-overlay .alert-wrapper {
            width: 90% !important;
        }
    }
}
.cg-form-group-description-space {
    min-height: 100px;
}
.cg-modal-footer {
    border-top: @cg-border-light;
    button:first-of-type {
        margin: 0 20px;
    }
}
</style>